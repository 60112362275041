/**
 * 关于假期方面的接口
 * 2022-01-23
 * author DuZeFang
 */
import request from '@/utils/request'

export default class HolidayApi {
	// 登录
	static detail(params) {
		return request({
			url: '/holiday/detail',
			method: 'post',
			data: params
		})
	}

	// 退出
	static store(params) {
		return request({
			url: '/holiday/store',
			method: 'post',
			data: params
		})
	}
}