<template>
	<div id="tu-holiday">
		<el-row :gutter="20">
			<el-col :span="4">
		    <SecondMenu type="1" />
			</el-col>
			<el-col :span="20">
				<div class="tu-title">
					<div>Leave</div>
					<div></div>
				</div>
				<div class="tu-body">
					<el-calendar :value="value" first-day-of-week="7">
				    <template #dateCell="{ data }">
				      <div :class="data.isSelected ? 'is-selected' : ''" style="width: 100%; height: 100%" @click="selectDay(data)">
				        {{ data.day.split('-').slice(2).join('-') }} 
				        <el-icon v-if="handleLifeDay(data.day)" style="color: var(--el-color-primary);"><i class="fa fa-hand-o-left"></i></el-icon>
				        <div class="cal-day" v-if="handleLifeLday(data.day, 0)">Full Day</div>
				        <div class="cal-day" v-if="handleLifeLday(data.day, 1)">Morning Leave</div>
				        <div class="cal-day" v-if="handleLifeLday(data.day, 2)">Afternoon Leave</div>
				        <div class="cal-day" v-if="handleLifeLday(data.day, 3)">Evening Leave</div>
				      </div>
				    </template>
				  </el-calendar>
				  <el-drawer
				    title="Leave Details"
				    v-model="drawer"
				    :direction="direction"
				    :before-close="handleClose"
				  >
				    <div style="padding: 5px 15px;">
							<el-form ref="form" :model="form" label-width="90px">
								<el-form-item label="Leave Date">
									<strong><el-icon><i class="fa fa-hand-o-right"></i></el-icon> {{ form.dayString }}</strong>
								</el-form-item>
								<el-form-item label="Full Day">
									<el-checkbox :checked="form.full_day==true?true:false" v-model="form.full_day"></el-checkbox>
								</el-form-item>
								<el-form-item label="Half Day">
									<el-checkbox-group v-model="form.zone" @change="onChangeZone">
										<!-- <el-checkbox label="0" name="0">All Day(00:00~23:59)</el-checkbox> -->
										<el-checkbox label="1" name="1">Morning Leave (pre ~ 12pm)</el-checkbox>
										<el-checkbox label="2" name="2">Afternoon Leave (12pm ~ 7pm)</el-checkbox>
										<el-checkbox label="3" name="3">Evening Leave (7pm ~ 12am)</el-checkbox>
									</el-checkbox-group>
								</el-form-item>
								<!-- <el-form-item label="Remark">
									<el-input type="textarea" v-model="form.desc"></el-input>
								</el-form-item> -->
								<el-form-item>
									<el-button type="primary" @click="onSubmit">SAVE</el-button>
									<el-button @click="drawer = !drawer">CANCEL</el-button>
									<el-button v-if="form.zone.length>0" @click="onClose">CLOSE</el-button>
								</el-form-item>
							</el-form>
						</div>
				  </el-drawer>
				</div>				
			</el-col>
		</el-row>
	</div>
</template>
<script>
import SecondMenu from '@/components/SecondMenu'
import HolidayApi from '@/api/holiday'

export default {
	name: "holiday",
	components: {
		SecondMenu
	},
	data() {
		return {
			drawer: false,
			direction: 'rtl',
			currentDate: new Date(),
			historyDate: [],
			form: {
				year: '',
				month: '',
				day: '',
				dayString: '',
				zone: [],
				desc: '',
				full_day: false
	    }
		}
	},
	created() {
		this.initDay();
	},
	methods: {
		initDay() {
      let currentDate = new Date();
      let data = {};
      data.year = currentDate.getFullYear();
      data.month = currentDate.getMonth() + 1;
      HolidayApi.detail(data).then(res => {
        if(res.code == 200) {
          this.historyDate = res.data || [];
        }
      });
    },
    selectDay(data) {
      this.form.dayString = data.day;
      let date = this.form.dayString.split('-');
      this.form.year = date[0];
      this.form.month = date[1];
      this.form.day = date[2];
      this.form.zone = [];
      this.form.desc = '';
      this.form.full_day = false;
      HolidayApi.detail(this.form).then(res => {
        if(res.code == 200) {
          if(!!res.data && res.data.zone) {
            this.form.zone = res.data.zone || [];
            this.form.full_day = res.data.zone.indexOf('0') != -1 ? true : false;
            this.form.desc = res.data.desc || '';
          }
          this.drawer = true;
        }
      });
    },
    handleLifeDay(day) {
      for(let i in this.historyDate) {
        if(this.historyDate[i].day == day) {
          return this.historyDate[i].zone.length > 0 ? true : false;
        }
      }
      return false;
    },
    handleLifeLday(day, zone) {
    	for(let i in this.historyDate) {
        if(this.historyDate[i].day == day) {
        	if(zone != 0 && this.historyDate[i].zone.indexOf('0') != -1) {
        		return false;
        	}
          return this.historyDate[i].zone.indexOf(zone.toString()) != -1 ? true : false;
        }
      }
      return false;
    },
    handleClose(done) {
      done();
    },
    onSubmit() {
      if(!!!this.form.dayString) {
        this.$message.error("You need to select a time before submitting");
        return ;
      }
      if(this.form.zone.indexOf('0') == -1 && this.form.full_day) {
      	this.form.zone.push('0');
      }
      if(!this.form.full_day) {
      	let izero = this.form.zone.indexOf('0');
      	if(izero != -1) {
      		this.form.zone.splice(izero, 1);
      	}
      }
      HolidayApi.store(this.form).then(res => {
        if(res.code != 200) {
          this.$message.error(res.message);
        } else {
          this.initDay();
          this.$message.success("Saved successfully");
          this.drawer = false;
        }
      })
    },
    onClose() {
    	this.form.zone = [];
    	this.form.full_day = false;
    	this.onSubmit();
    }
	},
  mounted() {
    // this.$nextTick(function() {
    //   let prevBtn1 = document.querySelector('.el-calendar__button-group .el-button-group>button:nth-child(1)');
    //   prevBtn1.addEventListener('click', () => {
    //     // console.log("shang ge yue");
    //   });
    //   let prevBtn2 = document.querySelector('.el-calendar__button-group .el-button-group>button:nth-child(2)');
    //   prevBtn2.addEventListener('click', () => {
    //     // console.log("today ge yue");
    //   });
    //   let prevBtn3 = document.querySelector('.el-calendar__button-group .el-button-group>button:nth-child(3)');
    //   prevBtn3.addEventListener('click', () => {
    //     // console.log("xia ge yue");
    //   });
    // })
  }
}
</script>
<style lang="less">
#tu-holiday {
	.tu-title {
		background: var(--el-color-primary);
		padding: 17px 15px;
		color: var(--el-color-white);
		text-align: left;
		font-weight: 700;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}

	.cal-day {
		font-size: var(--el-font-size-extra-small);
	}
}
</style>